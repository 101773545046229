import * as React from 'react';
import { Entities } from '@inwink/entities/entities';
import { logging } from '@inwink/logging';
import { EntityFieldBoolFilter } from './entityfilters.bool';
// import { EntityFieldSelectFilter } from './entityfilters.select';
import { EntityFieldMultiSelectFilter } from './entityfilters.multiselect';
import { EntityFieldEntityFilter } from './entityfilters.entity';
import { IEntityFiltersProps, IEntityFilterProps, IFilterProps } from './definitions';

import './entityfilters.less';

const logger = logging.getLogger("UI");

const needTemplate = ["multiselectlist", "selectlist" /*, "bool"*/];

export class EntityFilters extends React.Component<IEntityFiltersProps, any> {
    filterChanged = (arg: { field: Entities.IFilterFieldTemplate | Entities.IEntityFieldTemplate, val: any }) => {
        if (this.props.onFiltersChanged) {
            this.props.onFiltersChanged(arg.val);
        }
    };

    getActiveFilters = (fld: any) => {
        let existingtmp = (this.props.filters && this.props.filters[fld]) || [];
        if (typeof existingtmp === "string") existingtmp = [existingtmp];
        const existing = [];
        existingtmp.forEach((e) => {
            if (e.indexOf("+") !== -1) {
                const multifilter = e.split("+");
                multifilter.map((m) => existing.push(m));
            } else existing.push(e);
        });
        return existing;
    };

    getFilter(searchable: Entities.IFilterFieldTemplate, ix: number) {
        if (searchable.fromEntities) {
            if (searchable.mapping && searchable.label) {
                const props: IEntityFilterProps = {
                    key: searchable.name,
                    entitySchema: this.props.entitySchema,
                    basePredicate: this.props.basePredicate,
                    // eventid: this.props.event.eventid,
                    // i18n: this.props.i18n,
                    filters: this.props.filters || {},
                    // data: this.props.event.data,
                    filterField: searchable,
                    entityFilterHandler: this.props.entityFilterHandler,
                    filterChanged: this.filterChanged,
                    getActiveFilters: this.getActiveFilters,
                    requestManager: this.props.requestManager,
                    templatesData: this.props.templatesData
                };
                return React.createElement(EntityFieldEntityFilter, props);
            }
            logger.warn("entity filter " + searchable.name + " definition is incomplete");
        } else if (searchable.type && needTemplate.indexOf(searchable.type.toLowerCase()) === -1) {
            const filter = filtersComponents[searchable.type.toLowerCase()];
            if (filter) {
                const props: IFilterProps = {
                    key: searchable.name + ix,
                    basePredicate: this.props.basePredicate,
                    // eventid: this.props.event.eventid,
                    entitySchema: this.props.entitySchema,
                    i18n: this.props.i18n,
                    filters: this.props.filters || {},
                    entityField: null,
                    // data: this.props.event.data,
                    filterField: searchable,
                    filterChanged: this.filterChanged,
                    getActiveFilters: this.getActiveFilters,
                    requestManager: this.props.requestManager
                };
                return React.createElement(filter, props);
            }
        } else {
            const entityField = this.props.entitySchema?.template?.fields.filter((field) => {
                return field.key.toLowerCase() === searchable.name.toLowerCase();
            })[0];

            if (entityField) {
                const filter = filtersComponents[entityField.type.toLowerCase()];
                if (filter) {
                    const props: IFilterProps = {
                        key: entityField.key,
                        entitySchema: this.props.entitySchema,
                        basePredicate: this.props.basePredicate,
                        // eventid: this.props.event.eventid,
                        i18n: this.props.i18n,
                        filters: this.props.filters || {},
                        entityField: entityField,
                        filterField: searchable,
                        filterChanged: this.filterChanged,
                        getActiveFilters: this.getActiveFilters,
                        requestManager: this.props.requestManager
                    };
                    return React.createElement(filter, props);
                }
            }
        }
    }

    getFilters(props: IEntityFiltersProps) {
        const filters: Entities.IFilterFieldTemplate[] = [];
        const templateFilters = this.props.filtersSchema.searchablefields;
        if (templateFilters) {
            filters.push(...templateFilters);
        }
        if (props.customFilters) {
            filters.push(...props.customFilters);
        }
        return filters;
    }

    render() {
        //const filters = this.getFilters(this.props).map((f, ix) => this.getFilter(f, ix)).filter((f) => !!f);
        const filters = [];
        if (this.props.entitySchema && this.props.filtersSchema && this.props.filtersSchema.searchablefields) {
            this.props.filtersSchema.searchablefields.forEach((searchable, ix) => {
                if (searchable.isHidden) {
                    return;
                }                
                if ((searchable as any).userShouldBeLogged
                    // eslint-disable-next-line max-len
                    && (!this.props.user?.checked || !this.props.user.currentUser)) {
                    return;
                }
                if ((searchable as any).userShouldBeRegistered
                // eslint-disable-next-line max-len
                && (!this.props.user.currentUser || !this.props.user?.currentUser?.isRegistered)) {
                    return;
                }
                const filter = this.getFilter(searchable, ix);
                if (filter) {
                    filters.push(filter);
                }
            });
        }
        return <div className="entityfilters">{filters}</div>;
    }
}

const filtersComponents = {
    selectlist: EntityFieldMultiSelectFilter, // EntityFieldSelectFilter,
    multiselectlist: EntityFieldMultiSelectFilter,
    bool: EntityFieldBoolFilter
};

export function registerFilterComponent(key: string, component: any) {
    filtersComponents[key] = component;
}
