/* eslint-disable max-classes-per-file */
import * as React from 'react';
import * as debounce from 'lodash/debounce';
import type { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { Popover } from '@inwink/modals/popover';
import type { States } from '@@services/services';
import type { IItemsPickerProps, IItemsPickerItem } from "./itemspicker.props";
import { PagedList } from '@@components/PagedList';
import { InWinkInput } from 'helpers';
import type { IEntityFilterHandler } from './definitions';
import type { IInwinkEntityQueryOptions } from '@@api/front/datasource';
import { getItemsPickerThemeClass } from '@@modules/helpers';

export interface IItemsPickerAdvancedProps extends IItemsPickerProps {
    i18nHelper?: Entities.i18nHelper;
    selectedItems?: IItemsPickerItem[];
    entityFilterHandler: IEntityFilterHandler;
    entityName: string;
    filterField?: Entities.IFilterFieldTemplate;
    templatesData?: States.IDataStoreWithTemplates;
}

interface IItemsPickerAdvancedState {
    showItems: boolean;
    inputfilter: string;
    filter: string;
    pagedListFilters: any;
    itemsCount: number;
    labels: string[];
}

@withI18nHelper()
export class ItemsPickerAdvanced extends React.Component<IItemsPickerAdvancedProps, IItemsPickerAdvancedState> {
    itemPickerRowHeight = 36;

    searchInput = React.createRef<HTMLInputElement>();

    popoverCtrl = React.createRef<Popover>();

    constructor(props) {
        super(props);
        this.setFilter = debounce(this.setFilter, 200);
        this.searchChanged = debounce(this.searchChanged, 400);
        this.refreshPopover = debounce(this.refreshPopover, 200);

        this.state = {
            showItems: false,
            inputfilter: "",
            filter: "",
            pagedListFilters: {},
            itemsCount: 0,
            labels: []
        };
    }

    componentDidMount() {
        this.setState({labels: this.getLabelProperties()}, () => {
            this.popoverCtrl.current?.refresh();
        });
    }

    componentDidUpdate(prevProps: Readonly<IItemsPickerAdvancedProps>, prevState: Readonly<IItemsPickerAdvancedState>) {
        if (prevProps.selection?.length !== this.props.selection?.length) {
            this.setFilter();
        } else if (prevState.itemsCount !== this.state.itemsCount) {
            this.refreshPopover();
        }
    }

    refreshPopover = () => {
        this.popoverCtrl.current?.refresh();
    };

    onSearchFocus = () => {
        this.setState({ showItems: true });
    };

    toggleMultiSelectItem = (item: IItemsPickerItem) => {
        const newselection = this.props.selection ? [...this.props.selection] : [];
        const selectionIdx = newselection.indexOf(item.id);
        if (selectionIdx >= 0) {
            newselection.splice(selectionIdx, 1);
        } else {
            newselection.push(item.id);
        }
    };

    toggleEntityItem = (value: IItemsPickerItem) => {
        const newselection = this.props.selectedItems ? [...this.props.selectedItems] : [];
        const selectionIdx = newselection.findIndex((s) => s.id === value.id);

        let selectedValue = value;
        if (this.props.entityName && value?.[this.props.entityName]) {
            selectedValue = {...value, label: this.props.getItemLabel(value)};
        }

        if (selectionIdx >= 0) {
            newselection.splice(selectionIdx, 1);
        } else {
            newselection.push(selectedValue);
        }

        this.props.entitySelectionChanged(newselection);
    };

    hide = () => {
        this.setState({ showItems: false, inputfilter: "", filter: "" }, () => this.searchChanged(""));
    };

    setFilter = () => {
        this.setState((prevState) => ({ filter: prevState.inputfilter }));
        this.popoverCtrl.current?.refresh();
    };

    searchChanged = (txt: string) => {
        this.setState({pagedListFilters: this.getSearchExpression(txt)});
    };

    updateFilter = (arg) => {
        const txt = arg.currentTarget.value;
        this.setState(({ inputfilter: txt, filter: txt }), () => {
            this.searchChanged(txt);
        });
    };

    loadItems = (options: IInwinkEntityQueryOptions) => {
        const itemsOptions = {...options, search: this.state.filter, order: this.getOrderExpression()};
        return this.props.getItems(itemsOptions).then((res) => {
            if (itemsOptions.page.index === 0) {
                this.setState({itemsCount: res.length});
            }

            return [{data: res}];
        });
    };

    renderItem = (value: IItemsPickerItem, item, itemClicked) => {
        const isSelected = this.props.selection?.indexOf(value.id) >= 0;
        return <div
            key={item.key}
            style={item.style}
            className={"itemspicker-item clickable item-" + item.id + (isSelected ? " selected" : "")}
            onClick={itemClicked}
        >
            <div className={"item-checkmark" + (isSelected ? " bloc-accent" : "")}><i className="inwink-checked" /></div>
            <div className="item-label">{this.props.getItemLabel(value)}</div>
        </div>;
    };

    getSearchExpression = (txt: string) => {
        if (!txt)
            return {};

        const getExpression = (labelProperty: string) => {
            return {
                [labelProperty]: {
                    $text: {
                        $contains: txt
                    }
                }
            };
        };

        const expressions = this.state.labels.map((l) => {
            return getExpression(l);
        });

        if (expressions?.length)
            return { $and: expressions };

        return {};
    };

    getOrderExpression = () => {
        return this.state.labels.map((label) => {
            return {desc: false, by: {[label]: {}}};
        });
    };

    getLabelProperties = () => {
        const labels = this.props.filterField?.mapping?.label;
        if (labels)
            return labels;

        const lowerCaseEntityName = this.props.entityName?.toLowerCase();
        const fieldTemplateData = this.props.templatesData.fieldtemplates.data;

        const filterEntityTemplate = fieldTemplateData.find((t) => t.entityName === lowerCaseEntityName)?.template?.fields;
        if (filterEntityTemplate) {
            const labelProperties = ["name", "title"];
            return [filterEntityTemplate
                ?.filter((f) => f.isStatic && labelProperties.includes(f.key?.toLowerCase()))?.[0]?.key];
        }

        return [];
    };

    render() {
        if (!this.state.labels?.length)
            return;

        let items = null;
        const onItemClicked = this.props.entityName ? this.toggleEntityItem : this.toggleMultiSelectItem;
        if (this.props.selection && this.props.selection.length) {
            items = this.props.selection && this.props.selection.map((s) => {
                const item = this.props.selectedItems?.filter?.((it) => it.id === s)?.[0];
                if (item) {
                    const maxLabelSize = 25;
                    let itemLabel = item.label;
                    if (typeof itemLabel === "object") {
                        itemLabel = this.props.i18nHelper.translateBag(itemLabel);
                    }

                    itemLabel = itemLabel && itemLabel.length > maxLabelSize
                        ? itemLabel.slice(0, maxLabelSize) + "..." : itemLabel;
                    return <span className={"keywordbubble bloc-lightbg item-" + s} key={s}>
                        <span>{itemLabel}</span>
                        {!this.props.isReadonly &&
                        <span className="togglebtn clickable" onClick={() => onItemClicked(item)}>
                            <i className="inwink-dialog-cancel" /></span>
                        }
                    </span>;
                }
                return null;
            });
        }

        const themeClass = getItemsPickerThemeClass(this.props);
        return <div className="multiselect advanced">
            <div className="multiselect-content">{items}</div>
            {!this.props.isReadonly && <>
                <div className="searchbox">
                    <InWinkInput
                        ref={this.searchInput}
                        type="search"
                        placeholder={this.props.i18nHelper.translate("actions.clicktoselect")}
                        value={this.state.inputfilter}
                        onFocus={this.onSearchFocus}
                        onChange={this.updateFilter}
                    />
                    <i className="inwink-search" />
                </div>
            </>}
            {
                !this.props.isReadonly && this.state.showItems && <>
                    <Popover
                        ref={this.popoverCtrl}
                        show={true}
                        parent={this.searchInput.current}
                        className={"itemspicker-items-popover " + themeClass}
                        onhide={this.hide}
                        autofocus={false}>
                        <div style={{height: `${Math.min(this.state.itemsCount * this.itemPickerRowHeight, 300)}px`}} 
                            className="itemspicker-items-popover-items bloctheme">
                            <PagedList
                                loadItems={this.loadItems}
                                pageSize={50}
                                rowHeight={this.itemPickerRowHeight}
                                onItemClicked={onItemClicked}
                                onSearchTermChange={this.props.searchChanged}
                                noItems={null}
                                renderItem={this.renderItem}
                                filters={this.state.pagedListFilters}
                                rerenderOnClick={true}
                            />
                        </div>
                    </Popover>
                </>
            }
        </div>;
    }
}
